body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  height: 100%;
  display: flex;
  flex-direction: column;
}

html {
  height: -webkit-fill-available;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

main {
  flex-grow: 1;
}
